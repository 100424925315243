<script setup>
import Layout from "../../layouts/main.vue";
import mushroom from "cem-probe-api";
import { onMounted, reactive, ref, watch } from "vue";
import MethodService from "../../service/MethodService";
import { useRouter, useRoute } from "vue-router";
import DataForm from "./dataUseCase";
import toastr from "toastr";
const router = useRouter();
const route = useRoute();
import { datePicker } from "../../stores/datePicker";
const storeDatePicker = datePicker();
import { useI18n } from "vue-i18n";
const { t } = useI18n();
import ListColumnsName from "../../components/ListColumnsName.vue";
import ListColumnsFilter from "../../components/ListColumnsFilter";
import ListPagination from "../../components/ListPagination.vue";
import { marked } from "marked";
import { abortAPI } from "@/stores/abortAPI";

const tableRules = reactive(MethodService.copyObject(DataForm.tableRules));
const loadingData = ref(false);
const loadingBtn = ref(false);
const isClickDownload = ref(true); // nút download đang hiển thị
const isBtnLoading = ref(false); // nút Đang download
const idRequestDownload = ref("");
const UCID = ref();
const UC621ID = ref("");
const UC622ID = ref("");
const IdCardUC = ref();
const idCardItem = ref();
const dataTable = reactive({
  data: [],
  columnsOptionHide: [],
  selectColumnsToFilter: [],
  total: 0,
  totalPages: 0,
});
const isShowSERVERIP = ref(true);
const isShowCOUNTRY = ref(true);
const isShowERRORNUMBER = ref(true);
const isShowDATE62 = ref(true);
const isShowREQUESTNUMBER = ref(true);
const isShowCATEGORY = ref(false);
const isShowCUSTNUMBER = ref(true);
const isShowFAILCUSTNUMBER = ref(true);
const isShowISP = ref(true);
const isShowDOMAIN = ref(true);
const isShowDesc = ref(false);
const isShowNote = ref(true);
const isShowDELAYTOSERVER = ref(true);
const isShowATTN = ref(true);
const inputSearch = ref("");
const nameColumnToFilter = ref("");
const valueColumnToFilter = ref("");
const countCloseListFilter = ref(0);
const countCloseListColumnsName = ref(0);
const countCloseWarningData = ref(0);
const keyListColumnsFilter = ref(0);
const nameFilterURL = ref("");
const isDisabledPageNext = ref(false);
const isDisabledPagePrev = ref(true);
const positionColumnsHide = [];
const typeColumnToFilter = ref("input");
const storeAbortAPI = abortAPI();
const abortController = new AbortController();

const getUCIDByCode = async (codeName) => {
  try {
    const response = await mushroom.usecase.listAsync({
      filters: ["code=" + codeName],
    });
    UCID.value = response.result[0].id;
    const resUC621 = await mushroom.usecase.listAsync({
      filters: ["code=UC621"],
    });
    UC621ID.value = resUC621.result[0].id;
    const resUC622 = await mushroom.usecase.listAsync({
      filters: ["code=UC622"],
    });
    UC622ID.value = resUC622.result[0].id;
  } catch (e) {
    MethodService.showError(e.code);
  }
};
const getIdCardByUCID = async (id) => {
  try {
    const response = await mushroom.card.listAsync({
      filters: ["uc_id=" + id],
    });
    if (response.result) IdCardUC.value = response.result[0].id;
  } catch (e) {
    MethodService.showError(e.code);
  }
};
const getIdCardItem = async (id) => {
  try {
    const response = await mushroom.card_item.listAsync({
      filters: ["card_id=" + id],
    });
    if (response.result) idCardItem.value = response.result[0].id;
    dataTable.selectColumnsToFilter = [];
    dataTable.columnsOptionHide = [];
    response.result[0].table.format.forEach((item) => {
      if (item.filter.is_allowed)
        dataTable.selectColumnsToFilter.push({
          value: item.code,
          label: item.title_name,
          type: item.filter?.input_type ? item.filter.input_type : "input",
        });
      if (item.code) {
        dataTable.columnsOptionHide.push({
          value: item.code,
          label: item.title_name,
          field_name: item.field_name,
        });
      }
    });
  } catch (e) {
    MethodService.showError(e.code);
    tableRules.sort;
  }
};
const getDataSource = async () => {
  loadingData.value = true;

  let bodyData = {
    card_item_id: idCardItem.value,
    probe_id: localStorage.pid,
    extra: {},
    order: tableRules.sort,
    limit: tableRules.limit,
    offset: tableRules.offset,
    start_date: storeDatePicker.showDatePicker[0],
    stop_date: storeDatePicker.showDatePicker[1],
  };

  if (tableRules.filterKey.length > 0 && tableRules.filterValue.length > 0)
    bodyData.extra[tableRules.filterKey] = tableRules.filterValue.trim();

  let sortURL = "";
  let filterURL = "";
  if (tableRules.filterKey.length > 0 && tableRules.filterValue.length > 0)
    filterURL = tableRules.filterKey + ":" + tableRules.filterValue.trim();
  for (const [key, value] of Object.entries(tableRules.sort)) {
    value == 1 ? (sortURL = key) : (sortURL = "-" + key);
  }

  const search = location.search.substring(1);

  let queryForPush = Object.fromEntries(new URLSearchParams(search));
  queryForPush.start_date = MethodService.formatDateYYYYMMDD(
    storeDatePicker.showDatePicker[0]
  );
  queryForPush.stop_date = MethodService.formatDateYYYYMMDD(
    storeDatePicker.showDatePicker[1]
  );
  queryForPush.$sort = sortURL;
  queryForPush.$colfilter = filterURL;

  router
    .replace({
      name: "Usecases62",
      query: queryForPush,
    })
    .catch(() => {});
  try {
    const resDataSource = await mushroom.probe_data.getDatasourceAsync({
      body: bodyData,
      settings: {
        abortController
      }
    });
    dataTable.data = [];
    dataTable.total = resDataSource.result.total;
    dataTable.totalPages = Math.ceil(
      resDataSource.result.total / tableRules.limit
    );
    resDataSource.result.data.forEach((item) => {
      let obj = {};
      item.forEach((item1, index1) => {
        if (
          !resDataSource.result.columns[index1] ||
          !resDataSource.result.columns[index1].code
        )
          return;
        obj[resDataSource.result.columns[index1].code] = item1;
      });
      dataTable.data.push(obj);
    });
  } catch (e) {
    MethodService.showError(e.code);
  } finally {
    loadingData.value = false;
  }
};

const downloadCard = async () => {
  loadingBtn.value = true;
  isClickDownload.value = false;
  isBtnLoading.value = true;
  const search = location.search.substring(1);
  const data = search
    ? JSON.parse(
        '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
        function (key, value) {
          return key === "" ? value : decodeURIComponent(value);
        }
      )
    : "";

  // 1. gửi lên để có id request
  const request_download_dashboard_object = {
    probe_id: localStorage.pid,
    type: "uc",
    uc_id: UCID.value,
    start_date: storeDatePicker.showDatePicker[0],
    stop_date: storeDatePicker.showDatePicker[1],
    data: search ? JSON.stringify(data) : undefined,
  };
  try {
    const newId = await mushroom.request_download_dashboard.createAsync(
      request_download_dashboard_object
    );
    if (newId.result) {
      idRequestDownload.value = newId.result;
    }
    // 2. nếu request thành công, check trạng thái 5s 1 lần
    if (idRequestDownload.value.length > 0) {
      window.checkStatusDownloadProbe = setTimeout(checkStatus, 5000);
    } else clearTimeout(window.checkStatusDownloadProbe);
  } catch (e) {
    console.error("Có lỗi: %o", e);
    isClickDownload.value = true;
    isBtnLoading.value = false;
    window.toastr.error(e.detail);
  }
};
const checkStatus = async () => {
  let response;
  try {
    response = await mushroom.request_download_dashboard.findByIdAsync(
      {
        id: idRequestDownload.value,
      },
      { enabledCache: false }
    );
  } catch (e) {
    console.error("Có lỗi: %o", e);
    loadingBtn.value = false;
    isClickDownload.value = true;
    isBtnLoading.value = false;
    window.checkStatusDownloadProbe = setTimeout(checkStatus, 5000);
    return;
  }

  if (response.result.status == "error") {
    loadingBtn.value = false;
    isClickDownload.value = true;
    isBtnLoading.value = false;
    window.toastr.error(response.result.note);
    clearTimeout(window.checkStatusDownloadProbe);
    return;
  }

  if (response.result.status == "completed") {
    await MethodService.downloadFileDashboard(
      idRequestDownload.value,
      "dashboard_data.xlsx"
    );
    loadingBtn.value = false;
    isClickDownload.value = true;
    isBtnLoading.value = false;
    clearTimeout(window.checkStatusDownloadProbe);
    return;
  }
  window.checkStatusDownloadProbe = setTimeout(checkStatus, 5000);
};
const showColumn = (nameColumn) => {
  if (nameColumn == "date") isShowDATE62.value = true;
  if (nameColumn == "ip_server_str") isShowSERVERIP.value = true;
  if (nameColumn == "country") isShowCOUNTRY.value = true;
  if (nameColumn == "error_number") isShowERRORNUMBER.value = true;
  if (nameColumn == "request_number") isShowREQUESTNUMBER.value = true;
  if (nameColumn == "fail_cust_number") isShowFAILCUSTNUMBER.value = true;
  if (nameColumn == "cust_number") isShowCUSTNUMBER.value = true;
  if (nameColumn == "isp") isShowISP.value = true;
  if (nameColumn == "domain") isShowDOMAIN.value = true;
  if (nameColumn == "category") isShowCATEGORY.value = true;
  if (nameColumn == "sync_delay") isShowDELAYTOSERVER.value = true;
  if (nameColumn == "attention") isShowATTN.value = true;
  if (nameColumn == "domain_info") isShowDesc.value = true;
  if (nameColumn == "note") isShowNote.value = true;
};
const hideColumn = (nameColumn) => {
  if (nameColumn == "date") isShowDATE62.value = false;
  if (nameColumn == "ip_server_str") isShowSERVERIP.value = false;
  if (nameColumn == "country") isShowCOUNTRY.value = false;
  if (nameColumn == "error_number") isShowERRORNUMBER.value = false;
  if (nameColumn == "request_number") isShowREQUESTNUMBER.value = false;
  if (nameColumn == "fail_cust_number") isShowFAILCUSTNUMBER.value = false;
  if (nameColumn == "cust_number") isShowCUSTNUMBER.value = false;
  if (nameColumn == "isp") isShowISP.value = false;
  if (nameColumn == "domain") isShowDOMAIN.value = false;
  if (nameColumn == "category") isShowCATEGORY.value = false;
  if (nameColumn == "sync_delay") isShowDELAYTOSERVER.value = false;
  if (nameColumn == "attention") isShowATTN.value = false;
  if (nameColumn == "domain_info") isShowDesc.value = false;
  if (nameColumn == "note") isShowNote.value = false;
};
const nameColumnToFilterPlacehoder = (nameColumn, valueColumn, typeColumn) => {
  nameColumnToFilter.value = nameColumn;
  valueColumnToFilter.value = valueColumn;
  typeColumnToFilter.value = typeColumn ? typeColumn : "input";
  if (!tableRules.filterKey) inputSearch.value = "";
  if (typeColumn === "number")
    inputSearch.value = tableRules.filterValue.toString().slice(2);
  if (tableRules.filterValue && typeColumn !== "number")
    inputSearch.value = tableRules.filterValue;
};

const closeListFilter = () => {
  countCloseListFilter.value += 1;
};
const closeWarningData = () => {
  countCloseWarningData.value += 1;
};
const closeListColumnsName = () => {
  countCloseListColumnsName.value += 1;
};
const changePageFromListPagination = (pageSize) => {
  fn_tableSizeChange(pageSize);
};
const fn_tablePrevClick = () => {
  isDisabledPageNext.value = false;
  const pagePrev = tableRules.page - 1;
  if (pagePrev == 1) {
    isDisabledPagePrev.value = true;
  }
  tableRules.offset = (pagePrev - 1) * tableRules.limit;
  fn_tableChangeOffset(pagePrev);
};
const fn_tableNextClick = () => {
  isDisabledPagePrev.value = false;
  const pageNext = tableRules.page + 1;
  if (pageNext == dataTable.totalPages) {
    isDisabledPageNext.value = true;
  }
  tableRules.offset = (pageNext - 1) * tableRules.limit;
  fn_tableChangeOffset(pageNext);
};
const fn_tableSizeChange = (limit) => {
  tableRules.limit = limit;
  fn_tableChangeOffset(tableRules.page);
};
const fn_tableCurentChange = (page) => {
  fn_tableChangeOffset(page);
};
const fn_tableChangeOffset = async (page) => {
  tableRules.page = page;
  tableRules.offset = (tableRules.page - 1) * tableRules.limit;
  await getDataSource();
  page == 1
    ? (isDisabledPagePrev.value = true)
    : (isDisabledPagePrev.value = false);
  page == dataTable.totalPages
    ? (isDisabledPageNext.value = true)
    : (isDisabledPageNext.value = false);
};

const filterData = async () => {
  const keySearch =
    valueColumnToFilter.value || dataTable.selectColumnsToFilter[0].value;
  const typeSearch = typeColumnToFilter.value;
  let valueSearch = "";
  switch (typeSearch) {
    case "datepicker":
      valueSearch = MethodService.formatDateYYYYMMDD(inputSearch.value);
      break;
    case "tag_input":
      valueSearch = MethodService.formatFilterObjToStr(inputSearch.value);
      break;
    default:
      valueSearch = inputSearch.value;
  }
  tableRules.filterKey = keySearch;
  tableRules.offset = 0;
  if (typeSearch == "number" && inputSearch.value !== "") {
    if (valueSearch.match(/^[0-9.]+$/)) {
      valueSearch = `${keySearch === "priority" ? "<=" : ">="}` + valueSearch;
    } else if (!valueSearch.match(/^([><]?=?)[0-9.]+$/)) {
      toastr.warning(t("toastr_wrong_input_search"));
      return;
    }
  }

  tableRules.filterValue = valueSearch;
  await getDataSource();
};
const fn_tableSortChange = async (column) => {
  let obj = {};
  dataTable.columnsOptionHide.forEach((item) => {
    if (item.value === column.prop) column.prop = item.field_name;
  });
  if (column.order == "ascending") {
    obj[column.prop] = 1;
    tableRules.sort = obj;
  } else if (column.order == "descending") {
    obj[column.prop] = -1;
    tableRules.sort = obj;
  } else {
    tableRules.sort = "";
  }
  await getDataSource();
};

const getInfoURL = () => {
  if (route.query.$sort) {
    let strSort = route.query.$sort;
    let objSort = {};
    if (strSort.includes("-")) {
      let keySort = strSort.toString().replace("-", "");
      objSort[keySort] = -1;
      tableRules.sort = objSort;
    } else {
      objSort[strSort] = 1;
      tableRules.sort = objSort;
    }
    tableRules.defaultSort[0] = tableRules.sort;
    route.query.$sort.indexOf("-") !== -1
      ? (tableRules.defaultSort[1] = "descending")
      : (tableRules.defaultSort[1] = "ascending");
  }
  if (route.query.$colfilter) {
    let strFilterURL = route.query.$colfilter;
    let index = strFilterURL.toString().lastIndexOf(":");
    tableRules.filterKey = strFilterURL.toString().slice(0, index);
    tableRules.filterValue = strFilterURL.toString().slice(index + 1);
    inputSearch.value = tableRules.filterValue;
    nameFilterURL.value = tableRules.filterKey;
  }
};

const markdownToHTML = (text) => {
  if (text) {
    const html = marked(text);
    return html;
  }
  return "";
};

watch(
  () => storeDatePicker.showDatePicker,
  async (newDate) => {
    console.log(newDate);
    tableRules.offset = 0;
    getInfoURL();
    await getUCIDByCode("UC62");
    await getIdCardByUCID(UCID.value);
    await getIdCardItem(IdCardUC.value);
    await getDataSource();
    keyListColumnsFilter.value += 1;
  }
);

onMounted(async () => {
  storeAbortAPI.setAbort(abortController);
  console.log("uc 62");
  if (route.query?.start_date) {
    localStorage.setItem("dateSelect", [
      route.query.start_date,
      route.query.stop_date,
    ]);
    storeDatePicker.setDatePicker([
      route.query.start_date,
      route.query.stop_date,
    ]);
  }
  getInfoURL();
  await getUCIDByCode("UC62");
  await getIdCardByUCID(UCID.value);
  await getIdCardItem(IdCardUC.value);
  await getDataSource();
  keyListColumnsFilter.value += 1;
});
</script>

<template>
  <Layout>
    <div class="dashboard-all">
      <div class="dataUC">
        <div class="dataUC__header">
          <div class="dataUC__header-left">
            <div class="dataUC__header__pagination">
              <list-pagination
                :listPagesCount="tableRules.lengthMenu"
                :pageSize="tableRules.limit"
                @changePageFromListPagination="changePageFromListPagination"
              >
              </list-pagination>
            </div>
            <div class="dataUC__header__showCol">
              <list-columns-name
                :listColumns="dataTable.columnsOptionHide"
                :positionColumnsHide="positionColumnsHide"
                :countCloseListColumnsName="countCloseListColumnsName"
                @showColumn="showColumn"
                @hideColumn="hideColumn"
                @closeListFilter="closeListFilter"
                @closeWarningData="closeWarningData"
              ></list-columns-name>
            </div>
          </div>
          <div class="dataUC__header-mid">
            <div class="dataUC__header__filter">
              <list-columns-filter
                :listColumns="dataTable.selectColumnsToFilter"
                :countCloseListFilter="countCloseListFilter"
                :nameFilterURL="nameFilterURL"
                @nameColumnToFilter="nameColumnToFilterPlacehoder"
                @closeListColumnsName="closeListColumnsName"
                @closeWarningData="closeWarningData"
                :key="keyListColumnsFilter"
              ></list-columns-filter>
              <div class="dataUC__header__filter-icon">
                <span class="icon is-small is-left">
                  <img
                    v-if="
                      typeColumnToFilter == 'number' &&
                      valueColumnToFilter === 'priority'
                    "
                    src="../../assets/images/chart/nho_hoac_bang.svg"
                    height="18" />
                  <img
                    src="../../assets/images/chart/hon_hoac_bang.svg"
                    height="18"
                    v-if="
                      typeColumnToFilter == 'number' &&
                      valueColumnToFilter !== 'priority'
                    " />
                  <svg
                    v-if="
                      typeColumnToFilter !== 'number' &&
                      valueColumnToFilter !== 'priority'
                    "
                    class="svg-inline--fa fa-search"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="search"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path
                      class=""
                      fill="currentColor"
                      d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                    ></path></svg
                ></span>
                <div
                  v-if="typeColumnToFilter == 'datepicker'"
                  class="dataUC__header__filter-datePicker"
                >
                  <el-date-picker
                    v-model="inputSearch"
                    type="date"
                    :placeholder="$t('t_input_column_search_value', { msg: nameColumnToFilter })"
                    :prefix-icon="11"
                    format="YYYY-MM-DD"
                    @change="filterData"
                  />
                </div>
                <div
                  v-if="typeColumnToFilter == 'number'"
                  class="dataUC__header__filter-datePicker"
                >
                  <el-input
                    v-model="inputSearch"
                    class="w-100"
                    :placeholder="$t('t_input_column_search_value', { msg: nameColumnToFilter })"
                    @change="filterData"
                  />
                </div>
                <el-input
                  v-if="
                    typeColumnToFilter !== 'number' &&
                    typeColumnToFilter !== 'datepicker' &&
                    typeColumnToFilter !== 'tag_input'
                  "
                  v-model="inputSearch"
                  class="w-100"
                  :placeholder="$t('t_input_column_search_value', { msg: nameColumnToFilter })"
                  @change="filterData"
                />
              </div>
            </div>
          </div>
          <div class="dataUC__header-right">
            <div>
              <button
                v-if="isClickDownload"
                @click="downloadCard"
                type="button"
                class="button-download"
              >
                Excel
                <span class="icon is-small"
                  ><svg
                    class="svg-inline--fa fa-file-excel"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="file-excel"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                  >
                    <path
                      class=""
                      fill="currentColor"
                      d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm60.1 106.5L224 336l60.1 93.5c5.1 8-.6 18.5-10.1 18.5h-34.9c-4.4 0-8.5-2.4-10.6-6.3C208.9 405.5 192 373 192 373c-6.4 14.8-10 20-36.6 68.8-2.1 3.9-6.1 6.3-10.5 6.3H110c-9.5 0-15.2-10.5-10.1-18.5l60.3-93.5-60.3-93.5c-5.2-8 .6-18.5 10.1-18.5h34.8c4.4 0 8.5 2.4 10.6 6.3 26.1 48.8 20 33.6 36.6 68.5 0 0 6.1-11.7 36.6-68.5 2.1-3.9 6.2-6.3 10.6-6.3H274c9.5-.1 15.2 10.4 10.1 18.4zM384 121.9v6.1H256V0h6.1c6.4 0 12.5 2.5 17 7l97.9 98c4.5 4.5 7 10.6 7 16.9z"
                    ></path></svg
                ></span>
              </button>
              <el-button
                v-if="isBtnLoading"
                type="button"
                :loading="loadingBtn"
              >
              {{ $t('t-loading-get-data') }} ...
              </el-button>
            </div>
          </div>
        </div>
        <div class="dataUC__body">
          <el-table
            :data="dataTable.data"
            v-loading="loadingData"
            header-row-class-name="headerUC"
            @sort-change="fn_tableSortChange"
            :default-sort="{
              prop: tableRules.defaultSort[0],
              order: tableRules.defaultSort[1],
            }"
            :empty-text="$t('t-no-data')"
          >
            <el-table-column label="#" width="53" align="center">
              <template #default="scope">
                <div>
                  {{ scope.$index + tableRules.offset + 1 }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="date"
              label="Date"
              width="90"
              align="center"
              class-name="colStyle"
              v-if="isShowDATE62"
              sortable
            >
              <template #default="scope">
                <span
                  class="none-dataUC"
                  v-if="
                    scope.row.date !== null &&
                    scope.row.date !== undefined &&
                    scope.row.date !== '' &&
                    scope.row.date !== '-'
                  "
                  >{{ scope.row.date }}</span
                >
                <span v-else class="none-dataUC">-</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="ip_server_str"
              label="Server IP"
              width="120"
              align="center"
              v-if="isShowSERVERIP"
              class-name="colStyle"
              sortable
            >
              <template #default="scope">
                <span
                  v-if="
                    scope.row.ip_server_str !== null &&
                    scope.row.ip_server_str !== undefined &&
                    scope.row.ip_server_str !== '' &&
                    scope.row.ip_server_str !== '-'
                  "
                  class="colStyle__red widthServerIP"
                  v-html="MethodService.fnRenderServerIP(scope.row, UC621ID)"
                ></span>
                <span v-else class="none-dataUC">-</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="domain"
              label="Domain"
              min-width="170"
              align="center"
              class-name="colStyle"
              v-if="isShowDOMAIN"
              sortable
            >
              <template #default="scope">
                <el-tooltip
                  v-if="
                    scope.row.domain !== null &&
                    scope.row.domain !== undefined &&
                    scope.row.domain !== '' &&
                    scope.row.domain !== '-'
                  "
                  class="box-item"
                  popper-class="markdown markdown-domain"
                  effect="dark"
                  :content="`<b>${scope.row.domain}</b><br/>${markdownToHTML(
                    scope.row.domain_info
                  )}`"
                  raw-content
                  placement="top-start"
                >
                  <span
                    class="widthDomain"
                    v-html="MethodService.fnRenderDomain(scope.row)"
                  ></span>
                </el-tooltip>

                <span v-else class="none-dataUC">-</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="country"
              label="CC"
              min-width="75"
              align="center"
              class-name="colStyle"
              v-if="isShowCOUNTRY"
              sortable
            >
              <template #header>
                <el-tooltip
                  class="box-item"
                  effect="dark"
                  content="Country"
                  placement="top"
                >
                  <span class="header-tooltip">CC</span>
                </el-tooltip>
              </template>
              <template #default="scope">
                <span
                  class="none-dataUC"
                  v-if="
                    scope.row.country !== null &&
                    scope.row.country !== undefined &&
                    scope.row.country !== '' &&
                    scope.row.country !== '-'
                  "
                  >{{ scope.row.country }}</span
                >
                <span v-else class="none-dataUC">-</span>
              </template>
            </el-table-column>

            <el-table-column
              prop="error_number"
              label="Err"
              min-width="75"
              align="center"
              class-name="colStyle"
              v-if="isShowERRORNUMBER"
              sortable
            >
              <template #header>
                <el-tooltip
                  class="box-item"
                  effect="dark"
                  content="Number of Error Request"
                  placement="top"
                >
                  <span class="header-tooltip">Err</span>
                </el-tooltip>
              </template>
              <template #default="scope">
                <span
                  class="colStyle__err"
                  v-if="
                    scope.row.error_number !== null &&
                    scope.row.error_number !== undefined &&
                    scope.row.error_number !== '' &&
                    scope.row.error_number !== '-'
                  "
                  >{{ scope.row.error_number }}</span
                >
                <span v-else class="none-dataUC">-</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="request_number"
              label="TReq"
              min-width="90"
              align="center"
              class-name="colStyle"
              v-if="isShowREQUESTNUMBER"
              sortable
            >
              <template #header>
                <el-tooltip
                  class="box-item"
                  effect="dark"
                  content="Total Request"
                  placement="top"
                >
                  <span class="header-tooltip">TReq</span>
                </el-tooltip>
              </template>
              <template #default="scope">
                <span
                  class="colStyle__treq"
                  v-if="
                    scope.row.request_number !== null &&
                    scope.row.request_number !== undefined &&
                    scope.row.request_number !== '' &&
                    scope.row.request_number !== '-'
                  "
                  >{{ scope.row.request_number }}</span
                >
                <span v-else class="none-dataUC">-</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="sync_delay"
              label="Lat(ms)"
              min-width="110"
              align="center"
              class-name="colStyle"
              v-if="isShowDELAYTOSERVER"
              sortable
            >
              <template #header>
                <el-tooltip
                  class="box-item"
                  effect="dark"
                  content="Latency from Capture Point to this IP Address - ms"
                  placement="top"
                >
                  <span class="header-tooltip">Lat(ms)</span>
                </el-tooltip>
              </template>
              <template #default="scope">
                <span
                  class="none-dataUC"
                  v-if="
                    scope.row.sync_delay !== null &&
                    scope.row.sync_delay !== undefined &&
                    scope.row.sync_delay !== '' &&
                    scope.row.sync_delay !== '-'
                  "
                  >{{ scope.row.sync_delay }}</span
                >
                <span v-else class="none-dataUC">-</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="fail_cust_number"
              label="FCust"
              min-width="95"
              align="center"
              class-name="colStyle"
              v-if="isShowFAILCUSTNUMBER"
              sortable
            >
              <template #header>
                <el-tooltip
                  class="box-item"
                  effect="dark"
                  content="Number of Customers having an error request"
                  placement="top"
                >
                  <span class="header-tooltip">FCust</span>
                </el-tooltip>
              </template>
              <template #default="scope">
                <span
                  v-if="
                    scope.row.fail_cust_number !== null &&
                    scope.row.fail_cust_number !== undefined &&
                    scope.row.fail_cust_number !== '' &&
                    scope.row.fail_cust_number !== '-'
                  "
                  class="colStyle__neonblue"
                  v-html="
                    MethodService.fnRenderFailCustNumber(scope.row, UC622ID)
                  "
                ></span>
                <span v-else class="none-dataUC">-</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="cust_number"
              label="Cust"
              min-width="75"
              align="center"
              class-name="colStyle"
              v-if="isShowCUSTNUMBER"
              sortable
            >
              <template #default="scope">
                <span
                  class="none-dataUC"
                  v-if="
                    scope.row.cust_number !== null &&
                    scope.row.cust_number !== undefined &&
                    scope.row.cust_number !== '' &&
                    scope.row.cust_number !== '-'
                  "
                  >{{ scope.row.cust_number }}</span
                >
                <span v-else class="none-dataUC">-</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="isp"
              label="ISP"
              min-width="165"
              align="center"
              class-name="colStyle"
              v-if="isShowISP"
              sortable
            >
              <template #default="scope">
                <span
                  v-if="
                    scope.row.isp !== null &&
                    scope.row.isp !== undefined &&
                    scope.row.isp !== '' &&
                    scope.row.isp !== '-'
                  "
                  class="widthSlice60"
                  v-html="MethodService.fnRenderISP(scope.row)"
                ></span>
                <span v-else class="none-dataUC">-</span>
              </template>
            </el-table-column>

            <el-table-column
              prop="category"
              label="Category"
              min-width="110"
              align="center"
              class-name="colStyle"
              v-if="isShowCATEGORY"
              sortable
            >
              <template #default="scope">
                <span
                  v-if="
                    scope.row.category !== null &&
                    scope.row.category !== undefined &&
                    scope.row.category !== '' &&
                    scope.row.category !== '-'
                  "
                  class="widthSlice60"
                  v-html="MethodService.fnRenderCategoryUC62(scope.row)"
                ></span>
                <span v-else class="none-dataUC">-</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="attention"
              label="ATTN"
              min-width="75"
              align="center"
              class-name="colStyle"
              v-if="isShowATTN"
              sortable
            >
              <template #header>
                <el-tooltip class="box-item" effect="dark" placement="top">
                  <template #content>
                    <strong>ATTN</strong>: Attention<br /><strong>FST</strong>:
                    First Time for two day<br /><strong>NOTI</strong>:
                    Notification<br /><strong>OTH</strong>: Other
                  </template>

                  <span class="header-tooltip">ATTN</span>
                </el-tooltip>
              </template>
              <template #default="scope">
                <span
                  class="colStyle__attn"
                  v-if="
                    scope.row.attention !== null &&
                    scope.row.attention !== undefined &&
                    scope.row.attention !== '' &&
                    scope.row.attention !== '-'
                  "
                  >{{ scope.row.attention }}</span
                >
                <span v-else class="none-dataUC">-</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="note"
              min-width="300"
              align="center"
              class-name="colStyle"
              v-if="isShowNote"
            >
              <template #header>
                <el-tooltip
                  class="box-item"
                  effect="dark"
                  content="Note"
                  placement="top"
                >
                  <span class="header-tooltip">Note</span>
                </el-tooltip>
              </template>
              <template #default="scope">
                <span
                  class="none-dataUC markdown"
                  style="text-align: start; display: block"
                  v-if="
                    scope.row.note !== null &&
                    scope.row.note !== undefined &&
                    scope.row.note !== '' &&
                    scope.row.note !== '-'
                  "
                  v-html="markdownToHTML(scope.row.note)"
                ></span>
                <span v-else class="none-dataUC">-</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="domain_info"
              min-width="300"
              align="center"
              class-name="colStyle"
              v-if="isShowDesc"
            >
              <template #header>
                <el-tooltip
                  class="box-item"
                  effect="dark"
                  content="Service Description"
                  placement="top"
                >
                  <span class="header-tooltip">Desc</span>
                </el-tooltip>
              </template>
              <template #default="scope">
                <span
                  class="none-dataUC markdown"
                  style="text-align: start; display: block"
                  v-if="
                    scope.row.domain_info !== null &&
                    scope.row.domain_info !== undefined &&
                    scope.row.domain_info !== '' &&
                    scope.row.domain_info !== '-'
                  "
                  v-html="markdownToHTML(scope.row.domain_info)"
                ></span>
                <span v-else class="none-dataUC">-</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="dataUC__footer" v-if="dataTable.data.length > 0">
          <div class="dataUC__footer-total">
            {{
              $t("t_pagitation_usecase", {
                from: tableRules.offset + 1,
                to: tableRules.offset + tableRules.limit,
                total: dataTable.total,
              })
            }}
          </div>
          <div class="dataUC__footer-pagination">
            <el-pagination
              small
              background
              layout="pager"
              v-model:currentPage="tableRules.page"
              v-model:page-size="tableRules.limit"
              :page-sizes="tableRules.lengthMenu"
              :total="dataTable.total"
              @size-change="fn_tableSizeChange"
              @current-change="fn_tableCurentChange"
            />
            <button
              type="button"
              class="dataUC__footer-pagination-previous"
              @click="fn_tablePrevClick()"
              :disabled="isDisabledPagePrev || dataTable.totalPages == 1"
            >
              Previous
            </button>
            <button
              type="button"
              class="dataUC__footer-pagination-next"
              @click="fn_tableNextClick()"
              :disabled="isDisabledPageNext || dataTable.totalPages == 1"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<link type="scss" src="./usecase.scss" />
